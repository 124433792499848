<template>
  <div>
    <div class="main-title">用户列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div class="list-head" data-v-69874ad0="">
          <p class="list-head-title" data-v-69874ad0="">温馨提示</p>
          <ul data-v-69874ad0=""></ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select
            slot="prepend"
            v-model="searchType"
            placeholder="选择类型"
            size="mini"
          >
            <el-option :value="1" label="微信昵称"></el-option>
            <el-option :value="2" label="UID"></el-option>
          </el-select>
          <el-input
            v-model="userName"
            class="margin-l-sm"
            clearable
            size="mini"
            style="width: 160px"
          >
          </el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select
            v-model="platform"
            clearable
            placeholder="选择分类"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-date-picker
            v-model="value2"
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            size="small"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="margin-l-sm fl-l">
          <gift :user-list="multipleSelection" size="small" type="1" />
        </span>

        <span class="fl-r"> </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :cell-class-name="rowItem"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          row-key="id"
          style="width: 100%; background: #fff"
          @current-change="handleCurrentChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="头像" width="80">
            <template slot-scope="scope">
              <el-avatar
                :size="50"
                :src="scope.row.avatar_url"
                icon="el-icon-user-solid"
                shape="square"
              >
              </el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="用户名" property="index">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="可用学币" property="index">
            <template slot-scope="scope">
              <span>{{ scope.row.credit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="可用学币" property="index">
            <template slot-scope="scope">
              <span>{{ scope.row.platform == "1" ? "微信" : "抖音" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已购课程" property="index">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" v-html="course(scope.row.course)"></div>
                <span>{{
                  scope.row.course[0] === undefined ? "" : scope.row.course[0]
                }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="性别" property="index" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.gender === 0">保密</span>
              <span v-if="scope.row.gender === 1">男</span>
              <span v-if="scope.row.gender === 2">女</span>
            </template>
          </el-table-column>
          <el-table-column label="权限" property="index" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.role === 0">普通用户</span>
              <span v-if="scope.row.role === 1">超级权限</span>
              <span v-if="scope.row.role === 5">销售权限</span>
              <span v-if="scope.row.role === 6">教师权限</span>
              <span v-if="scope.row.role === 7">流量组员</span>
              <span v-if="scope.row.role === 8">流量组长</span>
              <span v-if="scope.row.role === 33">合作代理校区</span>
            </template>
          </el-table-column>
          <el-table-column label="绑定手机号" property="address">
            <template slot-scope="scope">
              <span v-if="scope.row.is_bind_phone == 1">已绑定</span>
              <span v-if="scope.row.is_bind_phone == 0">未绑定</span>
            </template>
          </el-table-column>
          <el-table-column label="注册时间" property="created_at" width="150">
          </el-table-column>
          <el-table-column label="vip时间" property="index">
            <template slot-scope="scope">
              <span>{{
                scope.row.vip_free_end_at === null
                  ? "无"
                  : scope.row.vip_free_end_at
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="功能限制" property="address" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.is_ban == 0" class="state-up"
                ><i></i>无限制</span
              >
              <span v-if="scope.row.is_ban == 1" class="state-down"
                ><i></i>已禁言</span
              >
            </template>
          </el-table-column>
          <el-table-column label="状态" property="address" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" class="state-up"
                ><i></i>正常</span
              >
              <span v-if="scope.row.status == 2" class="state-down"
                ><i></i>限制登录</span
              >
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" property="address">
            <template slot-scope="scope">
              <span>
                <el-tooltip
                  class="item"
                  content="恢复用户小程序的发布动态，作业，评论等功能"
                  effect="dark"
                  placement="top"
                >
                  <el-link
                    v-if="scope.row.is_ban == 1"
                    type="primary"
                    @click="setBan(scope.row, 0)"
                    >解禁
                  </el-link>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  content="禁言后用户无法使用小程序，发布动态，作业，评论等功能"
                  effect="dark"
                  placement="top"
                >
                  <el-link
                    v-if="scope.row.is_ban == 0"
                    type="primary"
                    @click="setBan(scope.row, 1)"
                    >禁言
                  </el-link>
                </el-tooltip>
              </span>
              -
              <span>
                <el-tooltip
                  class="item"
                  content="限制后登录，用户无法登录后台，小程序，网站等"
                  effect="dark"
                  placement="top"
                >
                  <el-link
                    v-if="scope.row.status == 1"
                    type="primary"
                    @click="setStatus(scope.row, 2)"
                    >限制登录</el-link
                  >
                </el-tooltip>

                <el-tooltip
                  class="item"
                  content="恢复用户登录，允许登录各个平台"
                  effect="dark"
                  placement="top"
                >
                  <el-link
                    v-if="scope.row.status == 2"
                    type="primary"
                    @click="setStatus(scope.row, 1)"
                    >允许登录</el-link
                  >
                </el-tooltip>
              </span>
              -
              <span>
                <el-popover
                  placement="bottom"
                  popper-class="no-padding"
                  trigger="click"
                >
                  <ul class="more-operation">
                    <el-popover
                      v-model="scope.row.show"
                      placement="left"
                      popper-class="no-padding"
                      trigger="click"
                    >
                      <div class="padding-sm">
                        <p
                          class="fl-l margin-r-sm"
                          style="height: 30px; line-height: 30px"
                        >
                          学币数量（负数为扣除）
                        </p>
                        <p class="fl-l">
                          <el-input-number
                            v-model="credit"
                            controls-position="right"
                            size="mini"
                          ></el-input-number>
                        </p>
                        <div class="fl-l margin-l-sm">
                          <el-button
                            :loading="btnLoad"
                            size="mini"
                            type="primary"
                            @click="presentedCredit(scope.row)"
                            >确定
                          </el-button>
                          <el-button
                            size="mini"
                            type="text"
                            @click="scope.row.show = false"
                          >
                            取消</el-button
                          >
                        </div>
                        <div class="clear"></div>
                      </div>
                      <li slot="reference" class="item">赠送学币</li>
                    </el-popover>
                    <li class="item">
                      <donate-vip :userid="scope.row.id"></donate-vip>
                    </li>
                    <li
                      v-if="
                        scope.row.role === 0 &&
                        $store.state.user.info.role === 1
                      "
                      class="item"
                      @click="
                        closeRoleDialog = true;
                        selectUser = scope.row;
                      "
                    >
                      开通权限
                    </li>
                    <li
                      class="item"
                      @click="
                        bookDialog = true;
                        selectUser = scope.row;
                      "
                    >
                      开通VIP
                    </li>
                    <li
                      class="item"
                      @click="
                        giveBookDialog = true;
                        selectUser = scope.row;
                      "
                    >
                      赠送书籍
                    </li>
                    <li
                      v-if="
                        scope.row.role !== 0 &&
                        $store.state.user.info.role === 1
                      "
                      class="item"
                      @click="
                        role = 0;
                        selectUser = scope.row;
                        setRole();
                      "
                    >
                      关闭权限
                    </li>
                    <li class="item" @click="showCloseVip(scope.row)">
                      取消会员权限
                    </li>
                  </ul>
                  <el-link slot="reference" type="primary">更多</el-link>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      :visible.sync="closeVipDialog"
      append-to-body
      title="关闭vip会员"
      width="30%"
    >
      <div>
        <el-input v-model="closeRemark" placeholder="关闭原因"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="hideCloseVip">取 消</el-button>
        <el-button
          :loading="closeBtn"
          size="mini"
          type="primary"
          @click="closeVip()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="closeRoleDialog"
      append-to-body
      title="开通权限"
      width="30%"
    >
      <div>
        <el-select
          v-model="role"
          clearable
          placeholder="请选择权限"
          size="mini"
        >
          <el-option label="超级权限" value="1"></el-option>
          <el-option label="销售权限" value="5"></el-option>
          <el-option label="教师权限" value="6"></el-option>
          <el-option label="流量组员" value="7"></el-option>
          <el-option label="流量组长" value="8"></el-option>
          <el-option label="合作代理校区" value="33"></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeRoleDialog = false"
          >取 消</el-button
        >
        <el-button
          :loading="closeBtn"
          size="mini"
          type="primary"
          @click="setRole()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="bookDialog"
      append-to-body
      title="开通VIP"
      width="30%"
    >
      <div style="padding: 10px">
        <div>
          <span>VIP类型:</span>
          <span style="width: 40px; display: inline-block"></span>
          <el-select v-model="VipData.module" placeholder="VIP类型">
            <el-option
              v-for="item in modules"
              :key="item.id"
              :label="item.name"
              :value="item.module"
            >
            </el-option>
          </el-select>
        </div>
        <div style="height: 12px"></div>
        <div>
          <span>赠送天数:</span>
          <span style="width: 40px; display: inline-block"></span>
          <el-input-number
            v-model="VipData.days"
            :min="1"
            label="赠送天数"
            size="mini"
          ></el-input-number>
        </div>
        <div style="height: 12px"></div>
        <div>
          <span>备注:</span>
          <span style="width: 40px; display: inline-block"></span>
          <el-input
            v-model="VipData.remark"
            :rows="2"
            placeholder="备注"
            type="textarea"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="bookDialog = false">取 消</el-button>
        <el-button
          :loading="closeBtn"
          size="mini"
          type="primary"
          @click="setVip()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="giveBookDialog"
      append-to-body
      style="padding: 10px"
      title="赠送书籍"
      width="50%"
    >
      <el-form
        ref="giveAudioBookData"
        :model="giveAudioBookData"
        class="demo-ruleForm"
        label-width="100px"
      >
        <el-form-item>
          <select-audio-book
            :userId="selectUser.id"
            @onDelete="onDelete"
            @onSelect="onSelect"
          >
          </select-audio-book>
        </el-form-item>
        <el-form-item>
          <span>赠送天数: </span>
          <el-input-number
            v-model="days"
            :min="1"
            label="赠送天数"
            size="mini"
          ></el-input-number>
        </el-form-item>
        <el-form-item style="padding: 20px">
          <el-button type="primary" @click="giveBook">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DonateVip from "../../../components/donateVip";
import selectAudioBook from "../../../components/selectAudioBook.vue";
import gift from "@/components/gift/index.vue";

export default {
  name: "list",
  components: { DonateVip, selectAudioBook, gift },
  data() {
    return {
      giveBookDialog: false,
      bookDialog: false,
      closeBtn: false,
      closeUser: null,
      closeRemark: "",
      closeVipDialog: false,
      closeRoleDialog: false,
      btnLoad: false,
      selectUser: {
        id: 0,
      },
      credit: 0,
      bindBtn: false,
      page: 1,
      pageSize: 10,
      total: 0,
      searchType: 1,
      userName: "",
      courseList: [],
      userCourse: [],
      courseIds: [],
      loading: false,
      bindLoading: false,
      imageUrl: config.imageUrl,
      platform: null,
      options: [
        {
          value: "1",
          label: "微信",
        },
        {
          value: "2",
          label: "抖音",
        },
      ],
      modules: [],
      VipData: {
        days: 1,
      },
      giveAudioBookData: {},
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tableData: [],
      currentRow: null,
      role: "",
      bookIds: [],
      days: 1,
      multipleSelection: [],
    };
  },
  methods: {
    ...mapActions("user", [
      "getUserList",
      "setUserBan",
      "setUserStatus",
      "setUserCredit",
      "setUserRole",
      "closeUserVip",
      "giveVip",
    ]),

    ...mapActions("hearBook", ["giveBooks"]),
    ...mapActions("common", ["vipSelectList"]),
    ...mapActions("course", ["getCourseList", "bindCourse"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    onSelect(e) {
      console.log(e);
      this.bookIds = e;
    },
    onDelete(e) {
      this.bookIds.splice(e, 1);
    },
    async setVip() {
      let form = {
        userId: this.selectUser.id,
        ...this.VipData,
      };
      const { data, res_info } = await this.giveVip(form);
      this.bookDialog = false;
      if (res_info != "ok") return;
      this.$message.success("开通成功");
    },
    async giveBook() {
      let bookIds = [];
      this.bookIds.forEach((v) => {
        bookIds.push(v.id);
      });
      let form = {
        userId: this.selectUser.id,
        bookIds,
        days: this.days,
      };
      const { data, res_info } = await this.giveBooks(form);
      this.giveBookDialog = false;
      if (res_info != "ok") return;
      this.$message.success("赠送成功");
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    selectCourse(row) {
      this.courseList = [];
      this.userCourse = [];
      this.courseIds = [];
      this.bindLoading = true;
      let list = [];
      this.getCourseList({ user_id: row.id }).then((res) => {
        res.data.course.forEach((course) => {
          course.chack = false;
          res.data.user_course.forEach((item) => {
            if (course.id == item.course_id) {
              this.courseIds.push(item.course_id);
            }
          });
          list.push(course);
        });

        this.courseList = list;
        this.userCourse = res.data.user_course;
        this.bindLoading = false;
      });
    },
    submitBind(row) {
      this.bindBtn = true;
      let userId = row.id;
      console.log(this.courseIds);

      let data = {
        user_id: userId,
        course_ids: this.courseIds,
      };
      this.bindCourse(data).then((res) => {
        if (res.ret == 0) {
          this.$message.success("绑定成功");
        } else {
          this.$message.error("绑定失败");
        }
        this.bindBtn = false;
      });
    },
    showCloseVip(row) {
      this.closeUser = row;
      this.closeVipDialog = true;
    },
    hideCloseVip() {
      this.closeVipDialog = false;
    },
    closeVip() {
      this.closeBtn = true;
      let data = {
        userId: this.closeUser.id,
        remark: this.closeRemark,
      };
      this.closeUserVip(data).then((res) => {
        if (res.ret === 0) {
          this.closeBtn = false;
          this.hideCloseVip();
          this.$message.success("取消成功");
          this.getList();
        }
      });
    },
    course(course) {
      let name = "";
      if (course) {
        course.forEach((item) => {
          name = name + "【" + item + "】" + "<br />";
        });
      }
      return name;
    },
    setBan(row, isBan) {
      this.loading = true;
      let data = {
        user_id: row.id,
        is_ban: isBan === 0 ? 0 : 1,
      };
      let message = isBan === 1 ? "用户已被限制发言" : "用户已解除禁言限制";
      this.setUserBan(data).then((res) => {
        this.$message.success(message);
        this.getList();
      });
    },
    setStatus(row, status) {
      this.loading = true;
      let data = {
        id: row.id,
        status: status,
      };
      let message = status == 1 ? "解除用户登录限制" : "已限制用户登录";
      this.setUserStatus(data).then((res) => {
        this.$message.success(message);
        this.getList();
      });
    },
    presentedCredit(e) {
      let data = {
        userId: e.id,
        credit: this.credit,
      };
      console.log(e);
      console.log(data);
      this.btnLoad = true;
      this.setUserCredit(data).then((res) => {
        console.log(res);
        this.btnLoad = false;
        this.$message.success("赠送成功！");
        this.getList();
      });
    },
    setRole() {
      console.log(this.role);
      let data = {
        role: this.role,
        userId: this.selectUser.id,
      };
      this.setUserRole(data).then((res) => {
        this.closeRoleDialog = false;
        this.$message.success("设置成功");
        this.getList();
      });
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    getList() {
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        platform: this.platform,
      };
      if (this.searchType === 1) {
        data.name = this.userName;
      } else {
        data.uid = this.userName;
      }
      // if(this.userName != ''){
      //     data.name = this.userName
      // }
      this.loading = true;
      this.getUserList(data).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;

        this.loading = false;
      });
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async selectList() {
      const { data } = await this.vipSelectList();
      this.modules = data;
    },
  },
  mounted() {
    this.getList();
    this.selectList();
  },
};
</script>

<style>
.el-popover {
  min-width: 80px;
}
</style>